import { useQuery } from '@tanstack/vue-query'
import { authQueries } from '..'
import { useSetPreferenceMutation } from '../mutations/useSetPreferenceMutation'
import { useToken } from './useToken'

export function usePreference<T extends PreferenceType>(params: {
  key: string
  defaultValue: T
  cast: SimpleConstructor<T>
}) {
  const token = useToken()
  const { data: authData } = useQuery({
    ...authQueries.reAuthQuery(token.value as string),
  })

  const { mutate } = useSetPreferenceMutation()

  const value = computed({
    get: () => {
      const dbValue = authData.value?.preferences[params.key]
      return dbValue ? params.cast(dbValue) : params.defaultValue
    },
    set: (newValue: PreferenceType) => {
      mutate({
        key: params.key,
        value: newValue.toString(),
      })
    },
  })

  return value
}

type PreferenceType = string | number | boolean

interface SimpleConstructor<T extends PreferenceType> {
  (value: any): T
}
