import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { AuthUser } from '../types'
import { useApi } from '~/domains/global/composables/useApi'

export const useSetPreferenceMutation = () => {
  const queryClient = useQueryClient()
  const api = useApi()

  return useMutation({
    mutationFn: async (params: {
      key: string
      value: string
    }) => {
      // Update the preferences in the cache
      queryClient.setQueryData<AuthUser>(['reauth'], (data) => {
        if (!data) return data

        return {
          ...data,
          preferences: {
            ...data.preferences,
            [params.key]: params.value,
          },
        }
      })

      // Update the preferences in the database
      await api(`/users/preferences`, {
        method: 'POST',
        body: params,
      })
    },
  })
}
